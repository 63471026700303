import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import Moment from 'moment';
import moment from 'moment-timezone';
import { extendMoment } from 'moment-range';

import * as _ from 'lodash';

import { loadReCaptcha } from 'react-recaptcha-v3';

import {
  Box,
  StepLabel,
  Step,
  Stepper,
  StepContent,
  Grid,
  Container,
  Divider,
  Typography,
  CircularProgress,
  RootRef,
} from '@material-ui/core';

import {
  getPublicShopDetails,
  getPublicShopAppointments,
  bookAppointment,
  getPublicShopAvailability,
} from '../actions';
import { isValidEmail, isBrowser, ccyFormat } from '../utils';

import BaseHOC from './BaseHOC';
import SEO from './seo';

import Contact from './Contact';
import OpeningHours from './OpeningHours';
import BookingFooter from './BookingFooter';
import BookingSuccess from './BookingSuccess';
import PrimaryButton from './PrimaryButton';

import ServiceSelectorStep from './BookingSteps/ServiceSelectorStep';
import BarberSelectorStep from './BookingSteps/BarberSelectorStep';
import AppointmentSelectorStep from './BookingSteps/AppointmentSelectorStep';
import CustomerDetailsStep from './BookingSteps/CustomerDetailsStep';

const momentRange = extendMoment(Moment);

const TransparentStepper = styled(Stepper)`
  && {
    background-color: inherit;
  }
`;

const PaddedGrid = styled(Grid)`
  && {
    padding: 2rem 0rem;
  }
`;

const BookBarber = ({ enqueueSnackbar, shopDetails, initialBarberId }) => {
  const { formatMessage: f, locale } = useIntl();
  const dispatch = useDispatch();
  const publicShopAvailabilities = useSelector(
    (state) => state.publicShopAvailabilities,
  );
  const publicShopAppointments = useSelector(
    (state) => state.publicShopAppointments,
  );
  const publicShopDetails = useSelector((state) => state.publicShopDetails);
  const apiRequest = useSelector((state) => state.apiRequest);
  const [activeStep, setActiveStep] = useState(initialBarberId ? 1 : 0);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedShop, setSelectedShop] = useState(null);
  const [barberId, setBarberId] = useState(initialBarberId);
  const [service, setService] = useState('');
  const [barber, setBarber] = useState(
    initialBarberId
      ? (publicShopDetails &&
          publicShopDetails.barbers &&
          publicShopDetails.barbers.find((x) => x.id === initialBarberId)) ||
          shopDetails.barbers.find((x) => x.id === initialBarberId)
      : '',
  );
  const [timeslot, setTimeslot] = useState('');
  const [clientName, setClientName] = useState('');
  const [clientNameError, setClientNameError] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [clientEmailError, setClientEmailError] = useState('');
  const [clientMobile, setClientMobile] = useState('');
  const [clientMobileError, setClientMobileError] = useState('');
  const [consentAccepted, setConsentAccepted] = useState(false);
  const [saveDetails, setSaveDetails] = useState(false);
  const [consentAcceptedError, setConsentAcceptedError] = useState('');
  const [nonFieldError, setNonFieldError] = useState('');
  const [token, setToken] = useState('');
  const [loader, setLoader] = useState(false);
  const [policyVersion, setPolicyVersion] = useState('');
  const [succesfulBooking, setSuccesfulBooking] = useState(false);
  const [messengerOptIn, setMessengerOptIn] = useState(false);
  const [userRef, setUserRef] = useState(
    Math.floor(Math.random() * 10000000000000 + 1),
  );

  const getSelectedBarber = () =>
    shopDetails.barbers.find(
      (x) => x.id.toString() === barberId.toString(),
    ) || {
      display_name: f({ id: 'bookingBookNoPreference' }),
    };

  const stepRefs = [useRef(), useRef(), useRef(), useRef()];

  useEffect(() => {
    if (activeStep > 0 && stepRefs[activeStep].current) {
      window.scrollTo({
        left: 0,
        top: stepRefs[activeStep - 1].current.offsetTop - 8,
        behavior: 'smooth',
      });
    }
  }, [activeStep]);

  useEffect(() => {
    if (isBrowser) {
      const details =
        JSON.parse(localStorage.getItem('customerDetails')) || null;
      if (details) {
        setSaveDetails(true);
        setClientName(details.clientName);
        setClientEmail(details.clientEmail);
        setClientMobile(details.clientMobile);
      }
    }
  }, [isBrowser]);

  useEffect(() => {
    if (isBrowser && shopDetails && shopDetails.shopId) {
      dispatch(getPublicShopDetails(shopDetails.shopId));
    }
  }, [isBrowser, shopDetails]);

  useEffect(() => {
    if (succesfulBooking) {
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    }
  }, [succesfulBooking]);

  const getServiceDurationForBarber = (selectedBarber) => {
    if (selectedBarber && service) {
      const levelId = (
        (
          (selectedBarber.service_levels &&
            selectedBarber.service_levels.find(
              (x) => x.service_base_id === service.base_id,
            )) ||
          {}
        ).level || {}
      ).id;

      if (service && levelId) {
        const levelInfo =
          service.service_levels.find((x) => x.level.id === levelId) || {};

        return levelInfo.duration;
      }
    }
    return service.duration;
  };

  const getServicePriceForBarber = (selectedBarber) => {
    if (selectedBarber && service) {
      const levelId = (
        (
          (selectedBarber.service_levels &&
            selectedBarber.service_levels.find(
              (x) => x.service_base_id === service.base_id,
            )) ||
          {}
        ).level || {}
      ).id;

      if (service && levelId) {
        const levelInfo =
          service.service_levels.find((x) => x.level.id === levelId) || {};

        return levelInfo.price;
      }
    }
    return service.price;
  };

  const getSteps = () => {
    return [
      <>
        <Box>{f({ id: 'bookingBookBarberStepTitle' })}</Box>
        {barberId && getSelectedBarber() ? (
          <Box fontWeight="fontWeightBold">
            {getSelectedBarber().display_name}
          </Box>
        ) : null}
      </>,
      <>
        <Box>{f({ id: 'bookingBookServiceStepTitle' })}</Box>
        {service ? (
          <Box fontWeight="fontWeightBold">
            <FormattedMessage
              id="bookingBookServiceFormat"
              values={{
                service: service.title,
                duration: getServiceDurationForBarber(getSelectedBarber()),
                price: ccyFormat(
                  getServicePriceForBarber(getSelectedBarber()),
                  shopDetails.country.currency,
                ),
              }}
            />
          </Box>
        ) : null}
      </>,
      <>
        <Box>{f({ id: 'bookingBookDateStepTitle' })}</Box>
        {selectedDate && timeslot ? (
          <Box fontWeight="fontWeightBold">
            {`${moment(selectedDate)
              .locale(locale)
              .format('YYYY-MM-DD')} ${timeslot}`}
          </Box>
        ) : null}
      </>,
      <Box>{f({ id: 'bookingBookCustomerStepTitle' })}</Box>,
    ];
  };

  const getBackLabels = () => {
    return [
      null,
      f({ id: 'bookingBookBackBarber' }),
      f({ id: 'bookingBookBackService' }),
      f({ id: 'bookingBookBackDate' }),
    ];
  };

  const setServiceFromId = (id) => {
    const selectedService = shopDetails.services.find(
      (x) => x.base_id === parseInt(id, 10),
    );

    setService(selectedService);
  };

  useEffect(() => {
    loadReCaptcha(process.env.GATSBY_RECAPTCHA_KEY);
    setPolicyVersion(shopDetails.policy && shopDetails.policy.uuid);
  }, [shopDetails]);

  useEffect(() => {
    if (barberId && service && selectedDate && selectedShop) {
      dispatch(
        getPublicShopAppointments({
          shopId: selectedShop?.id,
          barberId: barberId === -1 ? null : barberId,
          serviceId: service && service.id,
          bookingDate: moment(selectedDate).format(moment.HTML5_FMT.DATE),
          enqueueSnackbar,
        }),
      );
    }
  }, [selectedShop, service, barberId, selectedDate]);

  useEffect(() => {
    if (barberId) {
      dispatch(
        getPublicShopAvailability({
          shopId: shopDetails.shopId,
          barberId: barberId === -1 ? null : barberId,
          startDate: moment()
            .startOf('day')
            .format('YYYY-MM-DDTHH:mm:ss'),
          endDate: moment()
            .add(shopDetails.maximum_booking_wait_time, 'days')
            .endOf('day')
            .format('YYYY-MM-DDTHH:mm:ss'),
          enqueueSnackbar,
        }),
      );
    }
  }, [shopDetails, barberId]);

  useEffect(() => {
    if (apiRequest.inProgress) {
      setLoader(true);
    } else {
      setLoader(false);
      if (apiRequest.error) {
        setActiveStep(3);
      } else if (activeStep === null) {
        if (shopDetails.success_redirect_url && window) {
          window.location = shopDetails.success_redirect_url;
        } else {
          setSuccesfulBooking(true);
        }
      }
    }

    setClientEmailError('');
    setClientMobileError('');

    if (apiRequest.error) {
      apiRequest.error
        .map((x) => JSON.parse(x))
        .forEach((x) => {
          if (x.clientData) {
            Object.keys(x.clientData).forEach((y) => {
              if (y === 'clientEmail') setClientEmailError(x.clientData[y]);
              if (y === 'clientMobile') setClientMobileError(x.clientData[y]);
            });
          } else if (x.non_field_errors) {
            setNonFieldError(x.non_field_errors.join(', '));
          } else {
            console.log(x);
            setNonFieldError(x);
          }
        });
    }
  }, [apiRequest]);

  useEffect(() => {
    if (publicShopAvailabilities && selectedDate) {
      setSelectedShop(
        publicShopAvailabilities.filter(
          (x) => x.date === selectedDate.format(moment.HTML5_FMT.DATE),
        )[0]?.shop,
      );
    }
  }, [publicShopAvailabilities, selectedDate]);

  const handleMessengerCheckboxEvent = useCallback((e) => {
    if (e.event === 'checkbox') {
      setMessengerOptIn(e.state === 'checked');
    }
  }, []);

  const submitData = () => {
    let error = false;

    if (!consentAccepted) {
      setConsentAcceptedError(f({ id: 'bookingErrorTCs' }));
      error = true;
    } else {
      setConsentAcceptedError('');
    }

    if (!clientName) {
      setClientNameError(f({ id: 'bookingErrorName' }));
      error = true;
    } else {
      setClientNameError('');
    }

    if (!clientEmail) {
      setClientEmailError(f({ id: 'bookingErrorEmail' }));
      error = true;
    } else if (!isValidEmail(clientEmail)) {
      setClientEmailError(f({ id: 'bookingErrorInvalidEmail' }));
      error = true;
    } else {
      setClientEmailError('');
    }

    if (!clientMobile) {
      setClientMobileError(f({ id: 'bookingErrorMobile' }));
      error = true;
    } else {
      setClientMobileError('');
    }

    if (error) return;

    const bookedTimestamp = moment
      .tz(
        `${moment(selectedDate).format('YYYY-MM-DD')} ${timeslot}`,
        shopDetails.timezone,
      )
      .format();

    setActiveStep(null);

    if (saveDetails) {
      localStorage.setItem(
        'customerDetails',
        JSON.stringify({
          clientName,
          clientEmail,
          clientMobile,
        }),
      );
    }

    if (window.confirmOptIn) {
      window.confirmOptIn();
    }

    dispatch(
      bookAppointment(
        selectedShop?.id || shopDetails.shopId,
        {
          barberId: barberId === -1 ? null : barberId,
          serviceBaseId: service && service.base_id,
          token,
          bookedTimestamp,
          clientData: { clientName, clientEmail, clientMobile },
          consentAccepted,
          policyVersion,
          messengerOptIn,
          userRef,
        },
        enqueueSnackbar,
        f,
      ),
    );

    setUserRef(Math.floor(Math.random() * 10000000000000 + 1));
  };

  const steps = getSteps();

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const barbers =
    (publicShopDetails &&
      publicShopDetails.barbers &&
      publicShopDetails.barbers
        .filter((x) => !x.disable_booking)
        .filter(
          (x) =>
            !x.exclude_service_ids ||
            x.exclude_service_ids.indexOf(service.base_id) === -1,
        )) ||
    shopDetails.barbers
      .filter((x) => !x.disable_booking)
      .filter(
        (x) =>
          !x.exclude_service_ids ||
          x.exclude_service_ids.indexOf(service.base_id) === -1,
      ) ||
    [];

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <BarberSelectorStep
            value={barberId}
            onChange={(event) => {
              setBarberId(event.target.value);
              setBarber(
                (publicShopDetails &&
                  publicShopDetails.barbers.find(
                    (x) => x.id === event.target.value,
                  )) ||
                  shopDetails.barbers.find((x) => x.id === event.target.value),
              );
              if (event.target.value === -1) {
                setSelectedShop(null);
              }
              handleNext();
            }}
            options={
              (publicShopDetails &&
                publicShopDetails.barbers &&
                publicShopDetails.barbers.filter((x) => !x.disable_booking)) ||
              (shopDetails.barbers &&
                shopDetails.barbers.filter((x) => !x.disable_booking)) ||
              []
            }
          />
        );
      case 1:
        return (
          <>
            <ServiceSelectorStep
              value={service && service.base_id}
              onChange={(event) => {
                setServiceFromId(event.target.value);
                handleNext();
              }}
              country={shopDetails.country}
              options={
                (barber &&
                  ((publicShopDetails &&
                    publicShopDetails.services &&
                    publicShopDetails.services.filter(
                      (x) =>
                        barber.exclude_service_ids.indexOf(x.base_id) === -1,
                    )) ||
                    (shopDetails &&
                      shopDetails.services &&
                      shopDetails.services.filter(
                        (x) =>
                          barber.exclude_service_ids.indexOf(x.base_id) === -1,
                      )))) ||
                shopDetails.services
              }
              barber={barbers && barbers.find((x) => x.id === barberId)}
            />
          </>
        );
      case 2:
        return (
          publicShopAppointments && (
            <AppointmentSelectorStep
              closeDates={(shopDetails.close_dates || []).concat(
                Array.from(
                  momentRange
                    .range(
                      moment(),
                      moment().add(
                        shopDetails.maximum_booking_wait_time,
                        'days',
                      ),
                    )
                    .by('days'),
                )
                  .filter(
                    (x) =>
                      publicShopAvailabilities
                        .filter(
                          // Barber filter
                          (y) => barberId === -1 || barberId === y.employee,
                        )
                        .map((y) => y.date)
                        .indexOf(x.format(moment.HTML5_FMT.DATE)) === -1,
                  )
                  .map((x) => x.format(moment.HTML5_FMT.DATE)),
              )}
              maximumBookingWaitTime={shopDetails.maximum_booking_wait_time}
              appointments={publicShopAppointments.results}
              selectedShop={selectedShop}
              selectedDate={selectedDate}
              selectedTimeslot={timeslot}
              onDateChange={(value) => {
                setSelectedDate(value);
                if (barberId !== -1) {
                  setSelectedShop(
                    publicShopAvailabilities.filter(
                      (x) => x.date === value.format(moment.HTML5_FMT.DATE),
                    )[0]?.shop,
                  );
                }
              }}
              onClick={(e) => {
                setTimeslot(e.target.innerText.trim());
                handleNext();
              }}
              shopDetails={shopDetails}
            />
          )
        );
      case 3:
        return (
          <CustomerDetailsStep
            shopDetails={shopDetails}
            clientName={clientName}
            clientNameError={clientNameError}
            setClientName={setClientName}
            clientEmail={clientEmail}
            clientEmailError={clientEmailError}
            setClientEmail={setClientEmail}
            clientMobile={clientMobile}
            clientMobileError={clientMobileError}
            consentAccepted={consentAccepted}
            setConsentAccepted={setConsentAccepted}
            consentAcceptedError={consentAcceptedError}
            setClientMobile={setClientMobile}
            saveDetails={saveDetails}
            setSaveDetails={setSaveDetails}
            verifyCallback={(token) => setToken(token)}
            onMessengerCheckboxEvent={(e) => {
              handleMessengerCheckboxEvent(e);
            }}
            userRef={userRef}
            onClick={(e) => {
              submitData();
            }}
          />
        );
      default:
        return 'Unknown step';
    }
  };

  const canProgress = () => {
    return (
      (activeStep === 0 && service) ||
      (activeStep === 1 && barberId) ||
      (activeStep === 2 && timeslot)
    );
  };

  return (
    <>
      <SEO
        title={`${shopDetails.name} | ${f({ id: 'bookingDefaultSEOTitle' })}`}
        description={shopDetails.meta_description}
        thumbnailPreview={shopDetails.hero_image}
      />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width="100%"
        minHeight="100vh"
      >
        <Box>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justifyContent="center"
            alignContent="center"
          >
            <Grid item xs={12}>
              <Box
                textAlign="center"
                position="relative"
                mx="auto"
                pt={4}
                maxWidth="600px"
              >
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Typography variant="h3" component="h1" gutterBottom>
                      <Box fontWeight={600}>{shopDetails.name}</Box>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5" component="h2" gutterBottom>
                      <Box>
                        {(service && service.title) ||
                          f({ id: 'bookingDefaultSEOTitle' })}
                      </Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <PaddedGrid item xs={12}>
              <Container maxWidth="sm">
                {shopDetails.warning_message && (
                  <Typography variant="h6" align="center" color="error">
                    <Box pb={2}>{shopDetails.warning_message}</Box>
                  </Typography>
                )}
                <Typography variant="h6" align="center">
                  {f({ id: 'bookingFollowSteps' })}
                </Typography>
                <TransparentStepper
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                  activeStep={activeStep}
                  orientation="vertical"
                >
                  {steps.map((label, index) => (
                    <Step key={`step-${label}-${index}`}>
                      <StepLabel
                        onClick={() => {
                          if (index < activeStep) {
                            setActiveStep(index);
                          }
                        }}
                        style={{
                          cursor: index < activeStep ? 'pointer' : 'initial',
                        }}
                      >
                        <RootRef rootRef={stepRefs[index]}>{label}</RootRef>
                      </StepLabel>
                      <StepContent style={{ paddingLeft: 0 }}>
                        <>
                          <Box display="flex" justifyContent="center" m={1}>
                            {getStepContent(index)}
                          </Box>
                          <Box display="flex" justifyContent="center" m={2}>
                            {activeStep === 0 || activeStep === null ? null : (
                              <Box m={1} ml={3}>
                                <PrimaryButton onClick={handleBack}>
                                  {getBackLabels()[activeStep]}
                                </PrimaryButton>
                              </Box>
                            )}
                            {activeStep === 3 ? null : (
                              <Box m={1}>
                                <PrimaryButton
                                  onClick={handleNext}
                                  disabled={!canProgress()}
                                >
                                  OK
                                </PrimaryButton>
                              </Box>
                            )}
                          </Box>
                        </>
                      </StepContent>
                    </Step>
                  ))}
                </TransparentStepper>
                <Box display="flex" justifyContent="center">
                  {loader ? <CircularProgress /> : null}
                </Box>
                {nonFieldError ? (
                  <Box display="flex" justifyContent="center" bgcolor="#ffcdd2">
                    <Typography variant="body2">{nonFieldError}</Typography>
                  </Box>
                ) : null}
              </Container>
            </PaddedGrid>
          </Grid>
        </Box>
        <Box>
          <Divider />
          <Box mt={4}>
            <Container maxWidth="md">
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={6}>
                  <Contact shopDetails={shopDetails} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <OpeningHours openingHours={shopDetails.opening_hours} />
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Box mt={2}>
            <BookingFooter />
          </Box>
        </Box>
      </Box>
      <BookingSuccess
        open={succesfulBooking}
        onClick={() => navigate(`/shop/${shopDetails.slug}`)}
      />
    </>
  );
};

export default BaseHOC(BookBarber);
