// import React from 'react';

import styled from 'styled-components';
import { Button } from '@material-ui/core';

// import palette from '../../palette';

export const StyledButton = styled(Button)`
  && {
    margin-right: ${(props) => (props.withmargin ? '0.5rem' : '0rem')};
  }
`;
