import React from 'react';

import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

import { StyledButton } from './styles';

const dangerTheme = createTheme({
  palette: {
    primary: red,
  },
  typography: {
    useNextVariants: true,
  },
});

const Button = ({ danger, ...rest }) => {
  if (danger) {
    return (
      <MuiThemeProvider theme={dangerTheme}>
        <StyledButton variant="contained" color="primary" {...rest} />
      </MuiThemeProvider>
    );
  }
  return <StyledButton variant="contained" color="primary" {...rest} />;
};

export default Button;
