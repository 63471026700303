import React from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import 'moment/locale/hu';

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { Grid, Typography, Box } from '@material-ui/core';

import Chip from '../Chip';

// moment.locale('hu'); // it is required to select default locale manually

// moment.updateLocale('hu', {
//   week: {
//     dow: 1,
//   },
// });

const AppointmentSelectorStep = ({
  closeDates,
  selectedDate,
  selectedTimeslot,
  onDateChange,
  appointments,
  onClick,
  maximumBookingWaitTime,
  shopDetails,
  selectedShop,
}) => {
  const { formatMessage: f, locale } = useIntl();
  const am =
    (appointments &&
      appointments.length > 0 &&
      appointments.filter(
        (slot) =>
          parseInt(
            moment(slot.slot)
              .locale(locale)
              .tz(shopDetails.timezone)
              .format('HH'),
            10,
          ) < 12,
      )) ||
    [];
  const pm =
    (appointments &&
      appointments.length > 0 &&
      appointments.filter(
        (slot) =>
          parseInt(
            moment(slot.slot)
              .locale(locale)
              .tz(shopDetails.timezone)
              .format('HH'),
            10,
          ) >= 12 &&
          parseInt(
            moment(slot.slot)
              .locale(locale)
              .tz(shopDetails.timezone)
              .format('HH'),
            10,
          ) < 18,
      )) ||
    [];
  const evening =
    (appointments &&
      appointments.length > 0 &&
      appointments.filter(
        (slot) =>
          parseInt(
            moment(slot.slot)
              .locale(locale)
              .tz(shopDetails.timezone)
              .format('HH'),
            10,
          ) >= 18,
      )) ||
    [];

  const renderChip = (slot) => (
    <Chip
      selected={
        selectedTimeslot ===
        moment(slot.slot)
          .locale(locale)
          .tz(shopDetails.timezone)
          .format('HH:mm')
      }
      color="primary"
      label={moment(slot.slot)
        .locale(locale)
        .tz(shopDetails.timezone)
        .format('HH:mm')}
      clickable
      key={moment(slot.slot)
        .locale(locale)
        .tz(shopDetails.timezone)
        .format('HH:mm')}
      onClick={onClick}
      style={{ textOverflow: 'clip', minWidth: '60px' }}
    />
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          {f({ id: 'bookingAppointmentDate' })}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <MuiPickersUtilsProvider
          utils={MomentUtils}
          libInstance={moment}
          locale={moment.locale(locale)}
        >
          <DatePicker
            shouldDisableDate={(date) =>
              closeDates.indexOf(moment(date).format(moment.HTML5_FMT.DATE)) !==
              -1
            }
            autoOk
            disablePast
            maxDate={moment()
              .add(maximumBookingWaitTime, 'days')
              .format(moment.HTML5_FMT.DATE)}
            variant="static"
            openTo="date"
            value={selectedDate}
            onChange={onDateChange}
            cancelLabel={f({ id: 'buttonCancel' })}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={12}>
        {shopDetails?.show_booking_location ? (
          <Box mb={2}>
            <Typography variant="subtitle1">
              A foglalás helyszíne:
              <br />
              <span style={{ color: 'red' }}>
                {selectedShop
                  ? `${selectedShop.address.address_line_1} (${selectedShop.name})`
                  : `${shopDetails.address.address_line_1} (${shopDetails.name})`}
              </span>
            </Typography>
          </Box>
        ) : null}
        <Typography variant="subtitle1">
          {f({ id: 'bookingAppointmentTime' })}
        </Typography>
        {am.length > 0 ? (
          <Box mt={2}>
            <Typography variant="body2">
              {f({ id: 'bookingAppointmentAm' })}
            </Typography>
            {am.map((slot) => renderChip(slot))}
          </Box>
        ) : null}
        {pm.length > 0 ? (
          <Box mt={2}>
            <Typography variant="body2">
              {f({ id: 'bookingAppointmentPm' })}
            </Typography>
            {pm.map((slot) => renderChip(slot))}
          </Box>
        ) : null}
        {evening.length > 0 ? (
          <Box mt={2}>
            <Typography variant="body2">
              {f({ id: 'bookingAppointmentEvening' })}
            </Typography>
            {evening.map((slot) => renderChip(slot))}
          </Box>
        ) : null}
        {pm.length || am.length || evening.length ? null : (
          <Box mt={2}>
            <Typography variant="body2">
              {f({ id: 'bookingAppointmentNoFreeSlots' })}
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default AppointmentSelectorStep;
