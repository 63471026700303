import React from 'react';
import { IntlProvider } from 'react-intl';

import { isSalonzone } from '../utils';
import messages from '../data/messages';
import salonzoneMessages from '../data/messages/salonzone';
import BookBarber from '../components/BookBarber';
import FontSelector from '../components/FontSelector';

const BookBarberTemplate = ({
  pageContext: { shopDetails, barberId: initialBarberId },
}) => {
  const locale =
    shopDetails && shopDetails.country && shopDetails.country.language_key;
  return (
    <IntlProvider
      locale={locale}
      messages={isSalonzone ? salonzoneMessages[locale] : messages[locale]}
    >
      <FontSelector>
        <BookBarber
          initialBarberId={initialBarberId}
          shopDetails={shopDetails}
        />
      </FontSelector>
    </IntlProvider>
  );
};

export default BookBarberTemplate;
